@import "https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;800&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Work Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root, [data-theme] {
  color: hsl(var(--nextui-foreground));
  background-color: hsl(var(--nextui-background));
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.bottom-\[5\%\] {
  bottom: 5%;
}

.end-1 {
  inset-inline-end: .25rem;
}

.end-1\.5 {
  inset-inline-end: .375rem;
}

.end-3 {
  inset-inline-end: .75rem;
}

.end-auto {
  inset-inline-end: auto;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-8 {
  left: 2rem;
}

.left-\[10\%\] {
  left: 10%;
}

.left-\[5\%\] {
  left: 5%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-5 {
  right: 1.25rem;
}

.right-\[10\%\] {
  right: 10%;
}

.right-\[5\%\] {
  right: 5%;
}

.start-0 {
  inset-inline-start: 0;
}

.start-1\.5 {
  inset-inline-start: .375rem;
}

.start-2 {
  inset-inline-start: .5rem;
}

.start-3 {
  inset-inline-start: .75rem;
}

.start-auto {
  inset-inline-start: auto;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-5 {
  top: 1.25rem;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[calc\(100\%_\+_2px\)\] {
  top: calc(100% + 2px);
}

.top-\[var\(--navbar-height\)\] {
  top: var(--navbar-height);
}

.-z-30 {
  z-index: -30;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-0 {
  margin: 0;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-\[calc\(\(theme\(spacing\.5\)-theme\(spacing\.1\)\)\/2\)\] {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-\[calc\(\(theme\(spacing\.6\)-theme\(spacing\.3\)\)\/2\)\] {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-\[calc\(\(theme\(spacing\.7\)-theme\(spacing\.5\)\)\/2\)\] {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-\[calc\(\(theme\(spacing\.5\)-theme\(spacing\.1\)\)\/2\)\] {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-\[calc\(\(theme\(spacing\.6\)-theme\(spacing\.3\)\)\/2\)\] {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-\[calc\(\(theme\(spacing\.7\)-theme\(spacing\.5\)\)\/2\)\] {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-ms-2 {
  margin-inline-start: -.5rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-px {
  margin-bottom: 1px;
}

.me-2 {
  margin-inline-end: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[9px\] {
  margin-top: 9px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.\!h-auto {
  height: auto !important;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[--visual-viewport-height\] {
  height: var(--visual-viewport-height);
}

.h-\[100dvh\] {
  height: 100dvh;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[calc\(100dvh_-_var\(--navbar-height\)\)\] {
  height: calc(100dvh - var(--navbar-height));
}

.h-\[var\(--navbar-height\)\] {
  height: var(--navbar-height);
}

.h-\[var\(--picker-height\)\] {
  height: var(--picker-height);
}

.h-auto {
  height: auto;
}

.h-divider {
  height: var(--nextui-divider-weight);
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[20rem\] {
  max-height: 20rem;
}

.max-h-\[24rem\] {
  max-height: 24rem;
}

.max-h-\[28rem\] {
  max-height: 28rem;
}

.max-h-\[32rem\] {
  max-height: 32rem;
}

.max-h-\[36rem\] {
  max-height: 36rem;
}

.max-h-\[42rem\] {
  max-height: 42rem;
}

.max-h-\[48rem\] {
  max-height: 48rem;
}

.max-h-\[56rem\] {
  max-height: 56rem;
}

.max-h-\[64rem\] {
  max-height: 64rem;
}

.max-h-\[calc\(100\%_-_8rem\)\] {
  max-height: calc(100% - 8rem);
}

.max-h-\[none\] {
  max-height: none;
}

.max-h-full {
  max-height: 100%;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-14 {
  min-height: 3.5rem;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-3 {
  min-height: .75rem;
}

.min-h-3\.5 {
  min-height: .875rem;
}

.min-h-4 {
  min-height: 1rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-7 {
  min-height: 1.75rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-80 {
  min-height: 20rem;
}

.min-h-\[100dvh\] {
  min-height: 100dvh;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[calc\(100\%_-_16px\)\] {
  width: calc(100% - 16px);
}

.w-\[calc\(100\%_-_theme\(spacing\.6\)\)\] {
  width: calc(100% - 1.5rem);
}

.w-\[calc\(var\(--visible-months\)_\*_var\(--calendar-width\)\)\] {
  width: calc(var(--visible-months) * var(--calendar-width));
}

.w-auto {
  width: auto;
}

.w-divider {
  width: var(--nextui-divider-weight);
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-10 {
  min-width: 2.5rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-3 {
  min-width: .75rem;
}

.min-w-3\.5 {
  min-width: .875rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-w-7 {
  min-width: 1.75rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-9 {
  min-width: 2.25rem;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-\[1024px\] {
  max-width: 1024px;
}

.max-w-\[1280px\] {
  max-width: 1280px;
}

.max-w-\[1536px\] {
  max-width: 1536px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[768px\] {
  max-width: 768px;
}

.max-w-\[none\] {
  max-width: none;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[500px\] {
  --tw-translate-x: -500px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes appearance-in {
  0% {
    opacity: 0;
    transform: translateZ(0)scale(.95);
  }

  60% {
    opacity: .75;
    backface-visibility: hidden;
    webkit-font-smoothing: antialiased;
    transform: translateZ(0)scale(1.05);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)scale(1);
  }
}

.animate-\[appearance-in_1s_infinite\] {
  animation: 1s infinite appearance-in;
}

@keyframes drip-expand {
  0% {
    opacity: .2;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-drip-expand {
  animation: .42s linear drip-expand;
}

@keyframes indeterminate-bar {
  0% {
    transform: translateX(-50%)scaleX(.2);
  }

  100% {
    transform: translateX(100%)scaleX(1);
  }
}

.animate-indeterminate-bar {
  animation: 1.5s cubic-bezier(.65, .815, .735, .395) infinite indeterminate-bar;
}

.animate-none {
  animation: none;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spinner-ease-spin {
  animation: .8s infinite spinner-spin;
}

.animate-spinner-linear-spin {
  animation: .8s linear infinite spinner-spin;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.scroll-py-6 {
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-0 {
  gap: 0 !important;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[calc\(theme\(borderRadius\.large\)\/1\.5\)\] {
  border-radius: calc(var(--nextui-radius-large) / 1.5);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\/2\)\] {
  border-radius: calc(var(--nextui-radius-medium) / 2);
}

.rounded-\[calc\(theme\(borderRadius\.small\)\/2\)\] {
  border-radius: calc(var(--nextui-radius-small) / 2);
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-large {
  border-radius: var(--nextui-radius-large);
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-medium {
  border-radius: var(--nextui-radius-medium);
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-small {
  border-radius: var(--nextui-radius-small);
}

.\!rounded-e-none {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.\!rounded-s-none {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.rounded-b-large {
  border-bottom-right-radius: var(--nextui-radius-large);
  border-bottom-left-radius: var(--nextui-radius-large);
}

.rounded-b-medium {
  border-bottom-right-radius: var(--nextui-radius-medium);
  border-bottom-left-radius: var(--nextui-radius-medium);
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-small {
  border-bottom-right-radius: var(--nextui-radius-small);
  border-bottom-left-radius: var(--nextui-radius-small);
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t-large {
  border-top-left-radius: var(--nextui-radius-large);
  border-top-right-radius: var(--nextui-radius-large);
}

.rounded-t-medium {
  border-top-left-radius: var(--nextui-radius-medium);
  border-top-right-radius: var(--nextui-radius-medium);
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-small {
  border-top-left-radius: var(--nextui-radius-small);
  border-top-right-radius: var(--nextui-radius-small);
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-medium {
  border-width: var(--nextui-border-width-medium);
}

.border-small {
  border-width: var(--nextui-border-width-small);
}

.border-x-\[calc\(theme\(spacing\.5\)\/2\)\] {
  border-left-width: .625rem;
  border-right-width: .625rem;
}

.border-x-\[calc\(theme\(spacing\.6\)\/2\)\] {
  border-left-width: .75rem;
  border-right-width: .75rem;
}

.border-x-\[calc\(theme\(spacing\.7\)\/2\)\] {
  border-left-width: .875rem;
  border-right-width: .875rem;
}

.border-y-\[calc\(theme\(spacing\.5\)\/2\)\] {
  border-top-width: .625rem;
  border-bottom-width: .625rem;
}

.border-y-\[calc\(theme\(spacing\.6\)\/2\)\] {
  border-top-width: .75rem;
  border-bottom-width: .75rem;
}

.border-y-\[calc\(theme\(spacing\.7\)\/2\)\] {
  border-top-width: .875rem;
  border-bottom-width: .875rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-medium {
  border-bottom-width: var(--nextui-border-width-medium);
}

.border-solid {
  border-style: solid;
}

.border-dotted {
  border-style: dotted;
}

.border-none {
  border-style: none;
}

.\!border-danger {
  --tw-border-opacity: 1 !important;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity))) !important;
}

.border-background {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-border-opacity)));
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.border-danger-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-border-opacity)));
}

.border-danger-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger-200) / var(--nextui-danger-200-opacity, var(--tw-border-opacity)));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.border-default-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-border-opacity)));
}

.border-default-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-border-opacity)));
}

.border-default-900 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-900) / var(--nextui-default-900-opacity, var(--tw-border-opacity)));
}

.border-divider {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-border-opacity)));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.border-primary-100 {
  --tw-border-opacity: 1;
  border-color: rgb(202 253 245 / var(--tw-border-opacity));
}

.border-primary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(162 245 255 / var(--tw-border-opacity));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.border-secondary-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 239 242 / var(--tw-border-opacity));
}

.border-secondary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(214 218 225 / var(--tw-border-opacity));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.border-success-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-border-opacity)));
}

.border-success-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success-200) / var(--nextui-success-200-opacity, var(--tw-border-opacity)));
}

.border-success-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success-300) / var(--nextui-success-300-opacity, var(--tw-border-opacity)));
}

.border-transparent {
  border-color: #0000;
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.border-warning-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-border-opacity)));
}

.border-warning-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning-200) / var(--nextui-warning-200-opacity, var(--tw-border-opacity)));
}

.border-warning-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning-300) / var(--nextui-warning-300-opacity, var(--tw-border-opacity)));
}

.border-x-transparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-y-transparent {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-b-current {
  border-bottom-color: currentColor;
}

.border-b-danger {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.border-b-default {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.border-b-foreground {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.border-b-secondary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.border-b-success {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.border-b-warning {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-s-danger {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.border-s-foreground {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.border-s-primary {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.border-s-secondary {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.border-s-success {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.border-s-warning {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-danger-50 {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity))) !important;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-bg-opacity)));
}

.bg-background\/10 {
  background-color: hsl(var(--nextui-background) / .1);
}

.bg-background\/70 {
  background-color: hsl(var(--nextui-background) / .7);
}

.bg-background\/80 {
  background-color: hsl(var(--nextui-background) / .8);
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-content1 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content1) / var(--nextui-content1-opacity, var(--tw-bg-opacity)));
}

.bg-content3 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content3) / var(--nextui-content3-opacity, var(--tw-bg-opacity)));
}

.bg-current {
  background-color: currentColor;
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.bg-danger-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.bg-danger\/20 {
  background-color: hsl(var(--nextui-danger) / .2);
}

.bg-default {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.bg-default-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.bg-default-300\/50 {
  background-color: hsl(var(--nextui-default-300) / .5);
}

.bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.bg-default-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-50) / var(--nextui-default-50-opacity, var(--tw-bg-opacity)));
}

.bg-default-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-bg-opacity)));
}

.bg-default-800 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-800) / var(--nextui-default-800-opacity, var(--tw-bg-opacity)));
}

.bg-default\/40 {
  background-color: hsl(var(--nextui-default) / .4);
}

.bg-divider {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-bg-opacity)));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.bg-foreground\/10 {
  background-color: hsl(var(--nextui-foreground) / .1);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-overlay\/30 {
  background-color: hsl(var(--nextui-overlay) / .3);
}

.bg-overlay\/50 {
  background-color: hsl(var(--nextui-overlay) / .5);
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 253 245 / var(--tw-bg-opacity));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.bg-primary\/20 {
  background-color: #00badb33;
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 242 / var(--tw-bg-opacity));
}

.bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.bg-secondary-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 68 81 / var(--tw-bg-opacity));
}

.bg-secondary\/20 {
  background-color: #444f5f33;
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.bg-success-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.bg-success\/20 {
  background-color: hsl(var(--nextui-success) / .2);
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.bg-warning-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.bg-warning\/20 {
  background-color: hsl(var(--nextui-warning) / .2);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-stripe-gradient-danger {
  background-image: linear-gradient(45deg, hsl(var(--nextui-danger-200)) 25%, hsl(var(--nextui-danger)) 25%, hsl(var(--nextui-danger)) 50%, hsl(var(--nextui-danger-200)) 50%, hsl(var(--nextui-danger-200)) 75%, hsl(var(--nextui-danger)) 75%, hsl(var(--nextui-danger)));
}

.bg-stripe-gradient-default {
  background-image: linear-gradient(45deg, hsl(var(--nextui-default-200)) 25%, hsl(var(--nextui-default-400)) 25%, hsl(var(--nextui-default-400)) 50%, hsl(var(--nextui-default-200)) 50%, hsl(var(--nextui-default-200)) 75%, hsl(var(--nextui-default-400)) 75%, hsl(var(--nextui-default-400)));
}

.bg-stripe-gradient-primary {
  background-image: linear-gradient(45deg, hsl(var(--nextui-primary-200)) 25%, hsl(var(--nextui-primary)) 25%, hsl(var(--nextui-primary)) 50%, hsl(var(--nextui-primary-200)) 50%, hsl(var(--nextui-primary-200)) 75%, hsl(var(--nextui-primary)) 75%, hsl(var(--nextui-primary)));
}

.bg-stripe-gradient-secondary {
  background-image: linear-gradient(45deg, hsl(var(--nextui-secondary-200)) 25%, hsl(var(--nextui-secondary)) 25%, hsl(var(--nextui-secondary)) 50%, hsl(var(--nextui-secondary-200)) 50%, hsl(var(--nextui-secondary-200)) 75%, hsl(var(--nextui-secondary)) 75%, hsl(var(--nextui-secondary)));
}

.bg-stripe-gradient-success {
  background-image: linear-gradient(45deg, hsl(var(--nextui-success-200)) 25%, hsl(var(--nextui-success)) 25%, hsl(var(--nextui-success)) 50%, hsl(var(--nextui-success-200)) 50%, hsl(var(--nextui-success-200)) 75%, hsl(var(--nextui-success)) 75%, hsl(var(--nextui-success)));
}

.bg-stripe-gradient-warning {
  background-image: linear-gradient(45deg, hsl(var(--nextui-warning-200)) 25%, hsl(var(--nextui-warning)) 25%, hsl(var(--nextui-warning)) 50%, hsl(var(--nextui-warning-200)) 50%, hsl(var(--nextui-warning-200)) 75%, hsl(var(--nextui-warning)) 75%, hsl(var(--nextui-warning)));
}

.bg-stripe-size {
  background-size: 1.25rem 1.25rem;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-default-300\/50 {
  stroke: hsl(var(--nextui-default-300) / .5);
}

.stroke-1 {
  stroke-width: 1px;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.\!px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-6 {
  padding-inline-end: 1.5rem;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-\[1px\] {
  padding-left: 1px;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: Work Sans, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[0\.55rem\] {
  font-size: .55rem;
}

.text-\[0\.5rem\] {
  font-size: .5rem;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-large {
  font-size: var(--nextui-font-size-large);
  line-height: var(--nextui-line-height-large);
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-medium {
  font-size: var(--nextui-font-size-medium);
  line-height: var(--nextui-line-height-medium);
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-small {
  font-size: var(--nextui-font-size-small);
  line-height: var(--nextui-line-height-small);
}

.text-tiny {
  font-size: var(--nextui-font-size-tiny);
  line-height: var(--nextui-line-height-tiny);
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-extralight {
  font-weight: 200;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-\[32px\] {
  line-height: 32px;
}

.\!text-danger {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity))) !important;
}

.text-\[\#4BB543\] {
  --tw-text-opacity: 1;
  color: rgb(75 181 67 / var(--tw-text-opacity));
}

.text-\[\#eed202\] {
  --tw-text-opacity: 1;
  color: rgb(238 210 2 / var(--tw-text-opacity));
}

.text-background {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.text-danger-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-300) / var(--nextui-danger-300-opacity, var(--tw-text-opacity)));
}

.text-danger-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.text-danger-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-600) / var(--nextui-danger-600-opacity, var(--tw-text-opacity)));
}

.text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.text-danger\/80 {
  color: hsl(var(--nextui-danger) / .8);
}

.text-default-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-text-opacity)));
}

.text-default-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-600) / var(--nextui-default-600-opacity, var(--tw-text-opacity)));
}

.text-default-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-700) / var(--nextui-default-700-opacity, var(--tw-text-opacity)));
}

.text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-400) / var(--nextui-foreground-400-opacity, var(--tw-text-opacity)));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-500) / var(--nextui-foreground-500-opacity, var(--tw-text-opacity)));
}

.text-foreground-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-600) / var(--nextui-foreground-600-opacity, var(--tw-text-opacity)));
}

.text-foreground\/50 {
  color: hsl(var(--nextui-foreground) / .5);
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.text-primary-300 {
  --tw-text-opacity: 1;
  color: rgb(99 234 253 / var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(3 147 183 / var(--tw-text-opacity));
}

.text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.text-primary\/80 {
  color: #00badbcc;
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.text-secondary-300 {
  --tw-text-opacity: 1;
  color: rgb(178 187 199 / var(--tw-text-opacity));
}

.text-secondary-500 {
  --tw-text-opacity: 1;
  color: rgb(105 122 142 / var(--tw-text-opacity));
}

.text-secondary-600 {
  --tw-text-opacity: 1;
  color: rgb(84 98 117 / var(--tw-text-opacity));
}

.text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.text-secondary\/80 {
  color: #444f5fcc;
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.text-success-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-400) / var(--nextui-success-400-opacity, var(--tw-text-opacity)));
}

.text-success-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-500) / var(--nextui-success-500-opacity, var(--tw-text-opacity)));
}

.text-success-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.text-success-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-700) / var(--nextui-success-700-opacity, var(--tw-text-opacity)));
}

.text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.text-success\/80 {
  color: hsl(var(--nextui-success) / .8);
}

.text-transparent {
  color: #0000;
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.text-warning-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-400) / var(--nextui-warning-400-opacity, var(--tw-text-opacity)));
}

.text-warning-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-500) / var(--nextui-warning-500-opacity, var(--tw-text-opacity)));
}

.text-warning-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.text-warning-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-700) / var(--nextui-warning-700-opacity, var(--tw-text-opacity)));
}

.text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.text-warning\/80 {
  color: hsl(var(--nextui-warning) / .8);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-\[value\] {
  opacity: value;
}

.opacity-disabled {
  opacity: var(--nextui-disabled-opacity);
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_20px_20px_0px_rgb\(0_0_0\/0\.05\)\] {
  --tw-shadow: 0px 20px 20px 0px #0000000d;
  --tw-shadow-colored: 0px 20px 20px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-large {
  --tw-shadow: var(--nextui-box-shadow-large);
  --tw-shadow-colored: var(--nextui-box-shadow-large);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-medium {
  --tw-shadow: var(--nextui-box-shadow-medium);
  --tw-shadow-colored: var(--nextui-box-shadow-medium);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-small {
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger\/40 {
  --tw-shadow-color: hsl(var(--nextui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-default\/50 {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-foreground\/40 {
  --tw-shadow-color: hsl(var(--nextui-foreground) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/40 {
  --tw-shadow-color: #00badb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-secondary\/40 {
  --tw-shadow-color: #444f5f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success\/40 {
  --tw-shadow-color: hsl(var(--nextui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning\/40 {
  --tw-shadow-color: hsl(var(--nextui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.\!outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-background {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-ring-opacity)));
}

.ring-danger {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-ring-opacity)));
}

.ring-default {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-ring-opacity)));
}

.ring-focus, .ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 186 219 / var(--tw-ring-opacity));
}

.ring-secondary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(68 79 95 / var(--tw-ring-opacity));
}

.ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-ring-opacity)));
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.ring-warning {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-ring-opacity)));
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, 1));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-disabled {
  --tw-backdrop-opacity: opacity(var(--nextui-disabled-opacity));
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\!transition-none {
  transition-property: none !important;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[color\,opacity\] {
  transition-property: color, opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\,transform\] {
  transition-property: opacity, transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,background-color\,color\] {
  transition-property: transform, background-color, color;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,color\,left\,opacity\] {
  transition-property: transform, color, left, opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\!duration-100 {
  transition-duration: .1s !important;
}

.\!duration-150 {
  transition-duration: .15s !important;
}

.\!duration-200 {
  transition-duration: .2s !important;
}

.\!duration-250 {
  transition-duration: .25s !important;
}

.\!duration-300 {
  transition-duration: .3s !important;
}

.\!duration-500 {
  transition-duration: .5s !important;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.\!ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.\!ease-soft-spring {
  transition-timing-function: cubic-bezier(.155, 1.105, .295, 1.12) !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-auto {
  will-change: auto;
}

.will-change-transform {
  will-change: transform;
}

:root, .light, [data-theme="light"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --nextui-background: 0 0% 100%;
  --nextui-foreground-50: 0 0% 98.04%;
  --nextui-foreground-100: 240 4.76% 95.88%;
  --nextui-foreground-200: 240 5.88% 90%;
  --nextui-foreground-300: 240 4.88% 83.92%;
  --nextui-foreground-400: 240 5.03% 64.9%;
  --nextui-foreground-500: 240 3.83% 46.08%;
  --nextui-foreground-600: 240 5.2% 33.92%;
  --nextui-foreground-700: 240 5.26% 26.08%;
  --nextui-foreground-800: 240 3.7% 15.88%;
  --nextui-foreground-900: 240 5.88% 10%;
  --nextui-foreground: 201.82 24.44% 8.82%;
  --nextui-divider: 0 0% 6.67%;
  --nextui-divider-opacity: .15;
  --nextui-focus: 212.02 100% 46.67%;
  --nextui-overlay: 0 0% 0%;
  --nextui-content1: 0 0% 100%;
  --nextui-content1-foreground: 201.82 24.44% 8.82%;
  --nextui-content2: 240 4.76% 95.88%;
  --nextui-content2-foreground: 240 3.7% 15.88%;
  --nextui-content3: 240 5.88% 90%;
  --nextui-content3-foreground: 240 5.26% 26.08%;
  --nextui-content4: 240 4.88% 83.92%;
  --nextui-content4-foreground: 240 5.2% 33.92%;
  --nextui-default-50: 0 0% 98.04%;
  --nextui-default-100: 240 4.76% 95.88%;
  --nextui-default-200: 240 5.88% 90%;
  --nextui-default-300: 240 4.88% 83.92%;
  --nextui-default-400: 240 5.03% 64.9%;
  --nextui-default-500: 240 3.83% 46.08%;
  --nextui-default-600: 240 5.2% 33.92%;
  --nextui-default-700: 240 5.26% 26.08%;
  --nextui-default-800: 240 3.7% 15.88%;
  --nextui-default-900: 240 5.88% 10%;
  --nextui-default-foreground: 0 0% 0%;
  --nextui-default: 240 4.88% 83.92%;
  --nextui-primary-50: 212.5 92.31% 94.9%;
  --nextui-primary-100: 211.84 92.45% 89.61%;
  --nextui-primary-200: 211.84 92.45% 79.22%;
  --nextui-primary-300: 212.24 92.45% 68.82%;
  --nextui-primary-400: 212.14 92.45% 58.43%;
  --nextui-primary-500: 212.02 100% 46.67%;
  --nextui-primary-600: 212.14 100% 38.43%;
  --nextui-primary-700: 212.24 100% 28.82%;
  --nextui-primary-800: 211.84 100% 19.22%;
  --nextui-primary-900: 211.84 100% 9.61%;
  --nextui-primary-foreground: 0 0% 100%;
  --nextui-primary: 212.02 100% 46.67%;
  --nextui-secondary-50: 270 61.54% 94.9%;
  --nextui-secondary-100: 270 59.26% 89.41%;
  --nextui-secondary-200: 270 59.26% 78.82%;
  --nextui-secondary-300: 270 59.26% 68.24%;
  --nextui-secondary-400: 270 59.26% 57.65%;
  --nextui-secondary-500: 270 66.67% 47.06%;
  --nextui-secondary-600: 270 66.67% 37.65%;
  --nextui-secondary-700: 270 66.67% 28.24%;
  --nextui-secondary-800: 270 66.67% 18.82%;
  --nextui-secondary-900: 270 66.67% 9.41%;
  --nextui-secondary-foreground: 0 0% 100%;
  --nextui-secondary: 270 66.67% 47.06%;
  --nextui-success-50: 146.67 64.29% 94.51%;
  --nextui-success-100: 145.71 61.4% 88.82%;
  --nextui-success-200: 146.2 61.74% 77.45%;
  --nextui-success-300: 145.79 62.57% 66.47%;
  --nextui-success-400: 146.01 62.45% 55.1%;
  --nextui-success-500: 145.96 79.46% 43.92%;
  --nextui-success-600: 146.01 79.89% 35.1%;
  --nextui-success-700: 145.79 79.26% 26.47%;
  --nextui-success-800: 146.2 79.78% 17.45%;
  --nextui-success-900: 145.71 77.78% 8.82%;
  --nextui-success-foreground: 0 0% 0%;
  --nextui-success: 145.96 79.46% 43.92%;
  --nextui-warning-50: 54.55 91.67% 95.29%;
  --nextui-warning-100: 37.14 91.3% 90.98%;
  --nextui-warning-200: 37.14 91.3% 81.96%;
  --nextui-warning-300: 36.96 91.24% 73.14%;
  --nextui-warning-400: 37.01 91.26% 64.12%;
  --nextui-warning-500: 37.03 91.27% 55.1%;
  --nextui-warning-600: 37.01 74.22% 44.12%;
  --nextui-warning-700: 36.96 73.96% 33.14%;
  --nextui-warning-800: 37.14 75% 21.96%;
  --nextui-warning-900: 37.14 75% 10.98%;
  --nextui-warning-foreground: 0 0% 0%;
  --nextui-warning: 37.03 91.27% 55.1%;
  --nextui-danger-50: 339.13 92% 95.1%;
  --nextui-danger-100: 340 91.84% 90.39%;
  --nextui-danger-200: 339.33 90% 80.39%;
  --nextui-danger-300: 339.11 90.6% 70.78%;
  --nextui-danger-400: 339 90% 60.78%;
  --nextui-danger-500: 339.2 90.36% 51.18%;
  --nextui-danger-600: 339 86.54% 40.78%;
  --nextui-danger-700: 339.11 85.99% 30.78%;
  --nextui-danger-800: 339.33 86.54% 20.39%;
  --nextui-danger-900: 340 84.91% 10.39%;
  --nextui-danger-foreground: 0 0% 100%;
  --nextui-danger: 339.2 90.36% 51.18%;
  --nextui-divider-weight: 1px;
  --nextui-disabled-opacity: .5;
  --nextui-font-size-tiny: .75rem;
  --nextui-font-size-small: .875rem;
  --nextui-font-size-medium: 1rem;
  --nextui-font-size-large: 1.125rem;
  --nextui-line-height-tiny: 1rem;
  --nextui-line-height-small: 1.25rem;
  --nextui-line-height-medium: 1.5rem;
  --nextui-line-height-large: 1.75rem;
  --nextui-radius-small: 6px;
  --nextui-radius-medium: 8px;
  --nextui-radius-large: 12px;
  --nextui-border-width-small: 1px;
  --nextui-border-width-medium: 2px;
  --nextui-border-width-large: 3px;
  --nextui-box-shadow-small: 0px 0px 5px 0px #00000005, 0px 2px 10px 0px #0000000f, 0px 0px 1px 0px #0000004d;
  --nextui-box-shadow-medium: 0px 0px 15px 0px #00000008, 0px 2px 30px 0px #00000014, 0px 0px 1px 0px #0000004d;
  --nextui-box-shadow-large: 0px 0px 30px 0px #0000000a, 0px 30px 60px 0px #0000001f, 0px 0px 1px 0px #0000004d;
  --nextui-hover-opacity: .8;
}

.dark, [data-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --nextui-background: 0 0% 0%;
  --nextui-foreground-50: 240 5.88% 10%;
  --nextui-foreground-100: 240 3.7% 15.88%;
  --nextui-foreground-200: 240 5.26% 26.08%;
  --nextui-foreground-300: 240 5.2% 33.92%;
  --nextui-foreground-400: 240 3.83% 46.08%;
  --nextui-foreground-500: 240 5.03% 64.9%;
  --nextui-foreground-600: 240 4.88% 83.92%;
  --nextui-foreground-700: 240 5.88% 90%;
  --nextui-foreground-800: 240 4.76% 95.88%;
  --nextui-foreground-900: 0 0% 98.04%;
  --nextui-foreground: 210 5.56% 92.94%;
  --nextui-focus: 212.02 100% 46.67%;
  --nextui-overlay: 0 0% 0%;
  --nextui-divider: 0 0% 100%;
  --nextui-divider-opacity: .15;
  --nextui-content1: 240 5.88% 10%;
  --nextui-content1-foreground: 0 0% 98.04%;
  --nextui-content2: 240 3.7% 15.88%;
  --nextui-content2-foreground: 240 4.76% 95.88%;
  --nextui-content3: 240 5.26% 26.08%;
  --nextui-content3-foreground: 240 5.88% 90%;
  --nextui-content4: 240 5.2% 33.92%;
  --nextui-content4-foreground: 240 4.88% 83.92%;
  --nextui-default-50: 240 5.88% 10%;
  --nextui-default-100: 240 3.7% 15.88%;
  --nextui-default-200: 240 5.26% 26.08%;
  --nextui-default-300: 240 5.2% 33.92%;
  --nextui-default-400: 240 3.83% 46.08%;
  --nextui-default-500: 240 5.03% 64.9%;
  --nextui-default-600: 240 4.88% 83.92%;
  --nextui-default-700: 240 5.88% 90%;
  --nextui-default-800: 240 4.76% 95.88%;
  --nextui-default-900: 0 0% 98.04%;
  --nextui-default-foreground: 0 0% 100%;
  --nextui-default: 240 5.26% 26.08%;
  --nextui-primary-50: 211.84 100% 9.61%;
  --nextui-primary-100: 211.84 100% 19.22%;
  --nextui-primary-200: 212.24 100% 28.82%;
  --nextui-primary-300: 212.14 100% 38.43%;
  --nextui-primary-400: 212.02 100% 46.67%;
  --nextui-primary-500: 212.14 92.45% 58.43%;
  --nextui-primary-600: 212.24 92.45% 68.82%;
  --nextui-primary-700: 211.84 92.45% 79.22%;
  --nextui-primary-800: 211.84 92.45% 89.61%;
  --nextui-primary-900: 212.5 92.31% 94.9%;
  --nextui-primary-foreground: 0 0% 100%;
  --nextui-primary: 212.02 100% 46.67%;
  --nextui-secondary-50: 270 66.67% 9.41%;
  --nextui-secondary-100: 270 66.67% 18.82%;
  --nextui-secondary-200: 270 66.67% 28.24%;
  --nextui-secondary-300: 270 66.67% 37.65%;
  --nextui-secondary-400: 270 66.67% 47.06%;
  --nextui-secondary-500: 270 59.26% 57.65%;
  --nextui-secondary-600: 270 59.26% 68.24%;
  --nextui-secondary-700: 270 59.26% 78.82%;
  --nextui-secondary-800: 270 59.26% 89.41%;
  --nextui-secondary-900: 270 61.54% 94.9%;
  --nextui-secondary-foreground: 0 0% 100%;
  --nextui-secondary: 270 59.26% 57.65%;
  --nextui-success-50: 145.71 77.78% 8.82%;
  --nextui-success-100: 146.2 79.78% 17.45%;
  --nextui-success-200: 145.79 79.26% 26.47%;
  --nextui-success-300: 146.01 79.89% 35.1%;
  --nextui-success-400: 145.96 79.46% 43.92%;
  --nextui-success-500: 146.01 62.45% 55.1%;
  --nextui-success-600: 145.79 62.57% 66.47%;
  --nextui-success-700: 146.2 61.74% 77.45%;
  --nextui-success-800: 145.71 61.4% 88.82%;
  --nextui-success-900: 146.67 64.29% 94.51%;
  --nextui-success-foreground: 0 0% 0%;
  --nextui-success: 145.96 79.46% 43.92%;
  --nextui-warning-50: 37.14 75% 10.98%;
  --nextui-warning-100: 37.14 75% 21.96%;
  --nextui-warning-200: 36.96 73.96% 33.14%;
  --nextui-warning-300: 37.01 74.22% 44.12%;
  --nextui-warning-400: 37.03 91.27% 55.1%;
  --nextui-warning-500: 37.01 91.26% 64.12%;
  --nextui-warning-600: 36.96 91.24% 73.14%;
  --nextui-warning-700: 37.14 91.3% 81.96%;
  --nextui-warning-800: 37.14 91.3% 90.98%;
  --nextui-warning-900: 54.55 91.67% 95.29%;
  --nextui-warning-foreground: 0 0% 0%;
  --nextui-warning: 37.03 91.27% 55.1%;
  --nextui-danger-50: 340 84.91% 10.39%;
  --nextui-danger-100: 339.33 86.54% 20.39%;
  --nextui-danger-200: 339.11 85.99% 30.78%;
  --nextui-danger-300: 339 86.54% 40.78%;
  --nextui-danger-400: 339.2 90.36% 51.18%;
  --nextui-danger-500: 339 90% 60.78%;
  --nextui-danger-600: 339.11 90.6% 70.78%;
  --nextui-danger-700: 339.33 90% 80.39%;
  --nextui-danger-800: 340 91.84% 90.39%;
  --nextui-danger-900: 339.13 92% 95.1%;
  --nextui-danger-foreground: 0 0% 100%;
  --nextui-danger: 339.2 90.36% 51.18%;
  --nextui-divider-weight: 1px;
  --nextui-disabled-opacity: .5;
  --nextui-font-size-tiny: .75rem;
  --nextui-font-size-small: .875rem;
  --nextui-font-size-medium: 1rem;
  --nextui-font-size-large: 1.125rem;
  --nextui-line-height-tiny: 1rem;
  --nextui-line-height-small: 1.25rem;
  --nextui-line-height-medium: 1.5rem;
  --nextui-line-height-large: 1.75rem;
  --nextui-radius-small: 6px;
  --nextui-radius-medium: 8px;
  --nextui-radius-large: 12px;
  --nextui-border-width-small: 1px;
  --nextui-border-width-medium: 2px;
  --nextui-border-width-large: 3px;
  --nextui-box-shadow-small: 0px 0px 5px 0px #0000000d, 0px 2px 10px 0px #0003, inset 0px 0px 1px 0px #ffffff26;
  --nextui-box-shadow-medium: 0px 0px 15px 0px #0000000f, 0px 2px 30px 0px #00000038, inset 0px 0px 1px 0px #ffffff26;
  --nextui-box-shadow-large: 0px 0px 30px 0px #00000012, 0px 30px 60px 0px #00000042, inset 0px 0px 1px 0px #ffffff26;
  --nextui-hover-opacity: .9;
}

.leading-inherit {
  line-height: inherit;
}

.bg-img-inherit {
  background-image: inherit;
}

.bg-clip-inherit {
  background-clip: inherit;
}

.text-fill-inherit {
  -webkit-text-fill-color: inherit;
}

.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

.input-search-cancel-button-none::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.transition-background {
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-colors-opacity {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-width {
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-height {
  transition-property: height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-size {
  transition-property: width, height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-left {
  transition-property: left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-opacity {
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-background {
  transition-property: transform, background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-colors {
  transition-property: transform, color, background, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-colors-opacity {
  transition-property: transform, color, background, background-color, border-color, text-decoration-color, fill, stroke, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-default {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.scrollbar-default::-webkit-scrollbar {
  display: block;
}

.\[--picker-height\:224px\] {
  --picker-height: 224px;
}

.\[--scale-enter\:100\%\] {
  --scale-enter: 100%;
}

.\[--scale-exit\:100\%\] {
  --scale-exit: 100%;
}

.\[--scroll-shadow-size\:100px\] {
  --scroll-shadow-size: 100px;
}

.\[--slide-enter\:0px\] {
  --slide-enter: 0px;
}

.\[--slide-exit\:80px\] {
  --slide-exit: 80px;
}

.\[mask-image\:linear-gradient\(\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\] {
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

#lr_show_video {
  position: relative !important;
}

.score-table svg {
  display: inline-block;
}

.score-table tr td {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.file\:cursor-pointer::file-selector-button {
  cursor: pointer;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-transparent::file-selector-button {
  background-color: #0000;
}

.placeholder\:text-danger::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-foreground-500::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-500) / var(--nextui-foreground-500-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-primary::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.placeholder\:text-secondary::placeholder {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.placeholder\:text-success-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-warning-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.before\:z-0:before {
  content: var(--tw-content);
  z-index: 0;
}

.before\:z-\[-1\]:before {
  content: var(--tw-content);
  z-index: -1;
}

.before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-11:before {
  content: var(--tw-content);
  height: 2.75rem;
}

.before\:h-2\.5:before {
  content: var(--tw-content);
  height: .625rem;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-11:before {
  content: var(--tw-content);
  width: 2.75rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:w-6:before {
  content: var(--tw-content);
  width: 1.5rem;
}

.before\:-translate-x-full:before {
  content: var(--tw-content);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1:before {
  content: var(--tw-content);
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-0:before {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes shimmer {
  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.before\:animate-\[shimmer_2s_infinite\]:before {
  content: var(--tw-content);
  animation: 2s infinite shimmer;
}

.before\:animate-none:before {
  content: var(--tw-content);
  animation: none;
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.before\:rounded-sm:before {
  content: var(--tw-content);
  border-radius: .125rem;
}

.before\:border-2:before {
  content: var(--tw-content);
  border-width: 2px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-content4\/30:before {
  content: var(--tw-content);
  border-color: hsl(var(--nextui-content4) / .3);
}

.before\:border-danger:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.before\:border-default:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.before\:bg-content1:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content1) / var(--nextui-content1-opacity, var(--tw-bg-opacity)));
}

.before\:bg-current:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.before\:bg-danger:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.before\:bg-danger\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-danger) / .2);
}

.before\:bg-default\/60:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-default) / .6);
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.before\:bg-primary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.before\:bg-primary\/20:before {
  content: var(--tw-content);
  background-color: #00badb33;
}

.before\:bg-secondary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.before\:bg-secondary\/20:before {
  content: var(--tw-content);
  background-color: #444f5f33;
}

.before\:bg-success:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.before\:bg-success\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-success) / .2);
}

.before\:bg-warning:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.before\:bg-warning\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-warning) / .2);
}

.before\:bg-gradient-to-r:before {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.before\:from-transparent:before {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:via-content4:before {
  content: var(--tw-content);
  --tw-gradient-to: hsl(var(--nextui-content4) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--nextui-content4) / var(--nextui-content4-opacity, 1)) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.before\:to-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:shadow-small:before {
  content: var(--tw-content);
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:transition-colors:before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:transition-none:before {
  content: var(--tw-content);
  transition-property: none;
}

.before\:transition-transform:before {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:duration-150:before {
  content: var(--tw-content);
  transition-duration: .15s;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:transition-width:before {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0:after {
  content: var(--tw-content);
  inset: 0;
}

.after\:-bottom-1:after {
  content: var(--tw-content);
  bottom: -.25rem;
}

.after\:-bottom-\[2px\]:after {
  content: var(--tw-content);
  bottom: -2px;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:-z-10:after {
  content: var(--tw-content);
  z-index: -10;
}

.after\:z-0:after {
  content: var(--tw-content);
  z-index: 0;
}

.after\:ml-0\.5:after {
  content: var(--tw-content);
  margin-left: .125rem;
}

.after\:ms-0\.5:after {
  content: var(--tw-content);
  margin-inline-start: .125rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:h-\[2px\]:after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-divider:after {
  content: var(--tw-content);
  height: var(--nextui-divider-weight);
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:h-px:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-6:after {
  content: var(--tw-content);
  width: 1.5rem;
}

.after\:w-\[80\%\]:after {
  content: var(--tw-content);
  width: 80%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:origin-center:after {
  content: var(--tw-content);
  transform-origin: center;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-y-1:after {
  content: var(--tw-content);
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-0:after {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-50:after {
  content: var(--tw-content);
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-\[calc\(theme\(borderRadius\.large\)\/2\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-large) / 2);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\/3\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) / 3);
}

.after\:rounded-\[calc\(theme\(borderRadius\.small\)\/3\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-small) / 3);
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-none:after {
  content: var(--tw-content);
  border-radius: 0;
}

.after\:rounded-xl:after {
  content: var(--tw-content);
  border-radius: .75rem;
}

.after\:\!bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity))) !important;
}

.after\:bg-background:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-bg-opacity)));
}

.after\:bg-content1:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content1) / var(--nextui-content1-opacity, var(--tw-bg-opacity)));
}

.after\:bg-content3:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content3) / var(--nextui-content3-opacity, var(--tw-bg-opacity)));
}

.after\:bg-current:after {
  content: var(--tw-content);
  background-color: currentColor;
}

.after\:bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.after\:bg-default:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.after\:bg-default-foreground:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-bg-opacity)));
}

.after\:bg-divider:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-bg-opacity)));
}

.after\:bg-foreground:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.after\:bg-primary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.after\:bg-secondary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.after\:bg-success:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.after\:bg-warning:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.after\:text-danger:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.after\:text-danger-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-default-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-primary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-secondary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-success-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-warning-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.after\:opacity-0:after {
  content: var(--tw-content);
  opacity: 0;
}

.after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.after\:shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\]:after {
  content: var(--tw-content);
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:shadow-small:after {
  content: var(--tw-content);
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:transition-none:after {
  content: var(--tw-content);
  transition-property: none;
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:\!duration-200:after {
  content: var(--tw-content);
  transition-duration: .2s !important;
}

.after\:duration-150:after {
  content: var(--tw-content);
  transition-duration: .15s;
}

.after\:\!ease-linear:after {
  content: var(--tw-content);
  transition-timing-function: linear !important;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.after\:transition-background:after {
  content: var(--tw-content);
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-width:after {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-height:after {
  content: var(--tw-content);
  transition-property: height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-transform-opacity:after {
  content: var(--tw-content);
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.first\:-ml-0\.5:first-child {
  margin-left: -.125rem;
}

.first\:mt-2:first-child {
  margin-top: .5rem;
}

.first\:rounded-s-full:first-child {
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
}

.first\:rounded-s-large:first-child {
  border-start-start-radius: var(--nextui-radius-large);
  border-end-start-radius: var(--nextui-radius-large);
}

.first\:rounded-s-lg:first-child {
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.first\:rounded-s-medium:first-child {
  border-start-start-radius: var(--nextui-radius-medium);
  border-end-start-radius: var(--nextui-radius-medium);
}

.first\:rounded-s-none:first-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.first\:rounded-s-small:first-child {
  border-start-start-radius: var(--nextui-radius-small);
  border-end-start-radius: var(--nextui-radius-small);
}

.first\:before\:rounded-s-lg:first-child:before {
  content: var(--tw-content);
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.last\:rounded-e-full:last-child {
  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
}

.last\:rounded-e-large:last-child {
  border-start-end-radius: var(--nextui-radius-large);
  border-end-end-radius: var(--nextui-radius-large);
}

.last\:rounded-e-lg:last-child {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.last\:rounded-e-medium:last-child {
  border-start-end-radius: var(--nextui-radius-medium);
  border-end-end-radius: var(--nextui-radius-medium);
}

.last\:rounded-e-none:last-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.last\:rounded-e-small:last-child {
  border-start-end-radius: var(--nextui-radius-small);
  border-end-end-radius: var(--nextui-radius-small);
}

.last\:before\:rounded-e-lg:last-child:before {
  content: var(--tw-content);
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.first-of-type\:rounded-e-none:first-of-type {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.last-of-type\:rounded-s-none:last-of-type {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.autofill\:bg-transparent:-webkit-autofill {
  background-color: #0000;
}

.autofill\:bg-transparent:autofill {
  background-color: #0000;
}

.focus-within\:border-danger:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-default-400:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-default-foreground:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.focus-within\:border-secondary:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.focus-within\:border-success:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-warning:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.focus-within\:bg-danger-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-primary-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.focus-within\:bg-secondary-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.focus-within\:bg-success-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-warning-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:after\:w-full:focus-within:after {
  content: var(--tw-content);
  width: 100%;
}

.hover\:-translate-x-0:hover {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-default:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.hover\:border-default-300:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-border-opacity)));
}

.hover\:border-default-400:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.hover\:\!bg-foreground:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:bg-danger:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-danger-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-default-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-default-200:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(202 253 245 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 242 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(84 98 117 / var(--tw-bg-opacity));
}

.hover\:bg-success-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-warning-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:\!opacity-100:hover {
  opacity: 1 !important;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:after\:bg-danger\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-danger) / .2);
}

.hover\:after\:bg-foreground\/10:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-foreground) / .1);
}

.hover\:after\:bg-primary\/20:hover:after {
  content: var(--tw-content);
  background-color: #00badb33;
}

.hover\:after\:bg-secondary\/20:hover:after {
  content: var(--tw-content);
  background-color: #444f5f33;
}

.hover\:after\:bg-success\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-success) / .2);
}

.hover\:after\:bg-warning\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-warning) / .2);
}

.hover\:after\:opacity-100:hover:after {
  content: var(--tw-content);
  opacity: 1;
}

.focus-within\:hover\:border-danger:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-default-foreground:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-primary:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.focus-within\:hover\:border-secondary:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.focus-within\:hover\:border-success:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-warning:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:bg-default-100:hover:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-danger-400\/50:focus {
  background-color: hsl(var(--nextui-danger-400) / .5);
}

.focus\:bg-default-400\/50:focus {
  background-color: hsl(var(--nextui-default-400) / .5);
}

.focus\:bg-primary-400\/50:focus {
  background-color: #1cd6f480;
}

.focus\:bg-secondary-400\/50:focus {
  background-color: #8796a980;
}

.focus\:bg-success-400\/50:focus {
  background-color: hsl(var(--nextui-success-400) / .5);
}

.focus\:bg-warning-400\/50:focus {
  background-color: hsl(var(--nextui-warning-400) / .5);
}

.focus\:underline:focus {
  text-decoration-line: underline;
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:z-10:focus-visible {
  z-index: 10;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-focus:focus-visible {
  outline-color: #00badb;
}

.active\:bg-default-200:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.active\:bg-default-300:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-bg-opacity)));
}

.active\:underline:active {
  text-decoration-line: underline;
}

.active\:\!opacity-70:active {
  opacity: .7 !important;
}

.active\:opacity-disabled:active {
  opacity: var(--nextui-disabled-opacity);
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:border-current {
  border-color: currentColor;
}

.group:hover .group-hover\:text-current {
  color: currentColor;
}

.has-\[\:disabled\]\:opacity-60:has(:disabled) {
  opacity: .6;
}

.aria-expanded\:scale-\[0\.97\][aria-expanded="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aria-expanded\:opacity-70[aria-expanded="true"] {
  opacity: .7;
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"] {
  pointer-events: none;
}

.data-\[loaded\=true\]\:pointer-events-auto[data-loaded="true"], .data-\[visible\=true\]\:pointer-events-auto[data-visible="true"] {
  pointer-events: auto;
}

.data-\[focus-visible\=true\]\:z-10[data-focus-visible="true"], .data-\[focused\=true\]\:z-10[data-focused="true"] {
  z-index: 10;
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_10px\)\][data-has-label="true"] {
  margin-top: calc(var(--nextui-font-size-small)  + 10px);
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_12px\)\][data-has-label="true"] {
  margin-top: calc(var(--nextui-font-size-small)  + 12px);
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_8px\)\][data-has-label="true"] {
  margin-top: calc(var(--nextui-font-size-small)  + 8px);
}

.data-\[open\=true\]\:block[data-open="true"] {
  display: block;
}

.data-\[open\=true\]\:flex[data-open="true"] {
  display: flex;
}

.data-\[hidden\=true\]\:hidden[data-hidden="true"], .data-\[inert\=true\]\:hidden[data-inert="true"] {
  display: none;
}

.data-\[justify\=end\]\:flex-grow[data-justify="end"], .data-\[justify\=start\]\:flex-grow[data-justify="start"] {
  flex-grow: 1;
}

.data-\[justify\=end\]\:basis-0[data-justify="end"], .data-\[justify\=start\]\:basis-0[data-justify="start"] {
  flex-basis: 0;
}

.data-\[focus-visible\=true\]\:-translate-x-3[data-focus-visible="true"], .data-\[hover\=true\]\:-translate-x-3[data-hover="true"] {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[hover\=true\]\:translate-x-0[data-hover="true"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[before\=true\]\:rotate-180[data-before="true"], .data-\[direction\=ascending\]\:rotate-180[data-direction="ascending"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:-rotate-90[data-open="true"] {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:rotate-180[data-open="true"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:scale-100[data-active="true"] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:scale-110[data-active="true"] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[invisible\=true\]\:scale-0[data-invisible="true"] {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[pressed\=true\]\:scale-100[data-pressed="true"] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[pressed\=true\]\:scale-\[0\.97\][data-pressed="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[disabled\=true\]\:cursor-default[data-disabled="true"] {
  cursor: default;
}

.data-\[disabled\=true\]\:cursor-not-allowed[data-disabled="true"] {
  cursor: not-allowed;
}

.data-\[dragging\=true\]\:cursor-grabbing[data-dragging="true"] {
  cursor: grabbing;
}

.data-\[readonly\=true\]\:cursor-default[data-readonly="true"] {
  cursor: default;
}

.data-\[sortable\=true\]\:cursor-pointer[data-sortable="true"] {
  cursor: pointer;
}

.data-\[unavailable\=true\]\:cursor-default[data-unavailable="true"] {
  cursor: default;
}

.data-\[visible\=true\]\:cursor-pointer[data-visible="true"] {
  cursor: pointer;
}

.data-\[orientation\=horizontal\]\:flex-row[data-orientation="horizontal"] {
  flex-direction: row;
}

.data-\[has-helper\=true\]\:items-start[data-has-helper="true"] {
  align-items: flex-start;
}

.data-\[justify\=start\]\:justify-start[data-justify="start"] {
  justify-content: flex-start;
}

.data-\[justify\=end\]\:justify-end[data-justify="end"] {
  justify-content: flex-end;
}

.data-\[justify\=center\]\:justify-center[data-justify="center"] {
  justify-content: center;
}

.data-\[loaded\=true\]\:overflow-visible[data-loaded="true"] {
  overflow: visible;
}

.data-\[has-multiple-rows\=true\]\:rounded-large[data-has-multiple-rows="true"] {
  border-radius: var(--nextui-radius-large);
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:rounded-full[data-range-selection="true"][data-selection-end="true"][data-selected="true"], .data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:rounded-full[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  border-radius: 9999px;
}

.data-\[menu-open\=true\]\:border-none[data-menu-open="true"] {
  border-style: none;
}

.data-\[active\=true\]\:border-danger[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-danger-400[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger-400) / var(--nextui-danger-400-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-default-300[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-default-400[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-foreground[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-primary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.data-\[active\=true\]\:border-secondary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.data-\[active\=true\]\:border-success[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-warning[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-danger[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-default-400[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-default-foreground[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-primary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.data-\[focus\=true\]\:border-secondary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.data-\[focus\=true\]\:border-success[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-warning[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-danger[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default-400[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-primary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.data-\[hover\=true\]\:border-secondary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.data-\[hover\=true\]\:border-success[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-warning[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-danger[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-default-400[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-default-foreground[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-primary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.data-\[open\=true\]\:border-secondary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.data-\[open\=true\]\:border-success[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-warning[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:bg-danger[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-danger-100[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-danger-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-200) / var(--nextui-danger-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-default-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-default-400[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-primary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[active\=true\]\:bg-primary-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(162 245 255 / var(--tw-bg-opacity));
}

.data-\[active\=true\]\:bg-secondary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[active\=true\]\:bg-secondary-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 218 225 / var(--tw-bg-opacity));
}

.data-\[active\=true\]\:bg-success[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-success-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-200) / var(--nextui-success-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-warning[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-warning-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-200) / var(--nextui-warning-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:\!bg-danger[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-danger-100[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-default[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-primary[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity)) !important;
}

.data-\[hover\=true\]\:\!bg-secondary[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity)) !important;
}

.data-\[hover\=true\]\:\!bg-success[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-warning[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:bg-content2[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-danger) / .2);
}

.data-\[hover\=true\]\:bg-default[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-200[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-50) / var(--nextui-default-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default\/40[data-hover="true"] {
  background-color: hsl(var(--nextui-default) / .4);
}

.data-\[hover\=true\]\:bg-foreground-200[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground-200) / var(--nextui-foreground-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[hover\=true\]\:bg-primary-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.data-\[hover\=true\]\:bg-primary\/20[data-hover="true"] {
  background-color: #00badb33;
}

.data-\[hover\=true\]\:bg-secondary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[hover\=true\]\:bg-secondary-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.data-\[hover\=true\]\:bg-secondary\/20[data-hover="true"] {
  background-color: #444f5f33;
}

.data-\[hover\=true\]\:bg-success[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-success) / .2);
}

.data-\[hover\=true\]\:bg-transparent[data-hover="true"] {
  background-color: #0000;
}

.data-\[hover\=true\]\:bg-warning[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-warning) / .2);
}

.data-\[hover\=true\]\:bg-white[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.data-\[hover\]\:bg-danger-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-200) / var(--nextui-danger-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-danger-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-default-100[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-primary-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: rgb(162 245 255 / var(--tw-bg-opacity));
}

.data-\[hover\]\:bg-primary-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.data-\[hover\]\:bg-secondary-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 218 225 / var(--tw-bg-opacity));
}

.data-\[hover\]\:bg-secondary-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.data-\[hover\]\:bg-success-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-200) / var(--nextui-success-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-success-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-warning-100[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-warning-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-200) / var(--nextui-warning-200-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=false\]\:bg-default-200[data-in-range="false"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-background\/50[data-in-range="true"] {
  background-color: hsl(var(--nextui-background) / .5);
}

.data-\[in-range\=true\]\:bg-danger[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-foreground[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-primary[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[in-range\=true\]\:bg-secondary[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[in-range\=true\]\:bg-success[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-warning[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:\!bg-transparent[data-loaded="true"] {
  background-color: #0000 !important;
}

.data-\[selected\=true\]\:bg-danger[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-default[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-foreground[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-primary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:bg-secondary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:bg-success[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-warning[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-danger[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-foreground[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-primary[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-secondary[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-success[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-warning[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:data-\[outside-month\=true\]\:bg-transparent[data-outside-month="true"][data-range-selection="true"][data-selected="true"] {
  background-color: #0000;
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-danger[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-primary[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-secondary[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-success[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-warning[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-danger[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-primary[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-secondary[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-success[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-warning[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[has-end-content\=true\]\:pe-1\.5[data-has-end-content="true"] {
  padding-inline-end: .375rem;
}

.data-\[has-helper\=true\]\:pb-\[calc\(theme\(fontSize\.tiny\)_\+8px\)\][data-has-helper="true"], .data-\[has-helper\=true\]\:pb-\[calc\(theme\(fontSize\.tiny\)_\+_8px\)\][data-has-helper="true"] {
  padding-bottom: calc(var(--nextui-font-size-tiny)  + 8px);
}

.data-\[has-start-content\=true\]\:ps-1\.5[data-has-start-content="true"] {
  padding-inline-start: .375rem;
}

.data-\[has-title\=true\]\:pt-1[data-has-title="true"] {
  padding-top: .25rem;
}

.data-\[active\=true\]\:font-semibold[data-active="true"] {
  font-weight: 600;
}

.data-\[active\=true\]\:text-danger-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-default-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-primary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-secondary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-success-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-warning-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[disabled\=true\]\:text-default-300[data-disabled="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-danger-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-300) / var(--nextui-danger-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-foreground-500[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-500) / var(--nextui-foreground-500-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-primary-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(99 234 253 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-secondary-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(178 187 199 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-success-400[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-400) / var(--nextui-success-400-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-warning-400[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-400) / var(--nextui-warning-400-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-danger[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-foreground[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-primary[data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:text-secondary[data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:text-success-600[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-warning-600[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[has-value\=true\]\:text-default-foreground[data-has-value="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:\!text-danger-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-primary-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-secondary-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-success-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-warning-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:text-danger[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger-500[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-500[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-foreground-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-400) / var(--nextui-foreground-400-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-foreground-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-600) / var(--nextui-foreground-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[hover\=true\]\:text-primary-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: rgb(28 214 244 / var(--tw-text-opacity));
}

.data-\[hover\=true\]\:text-primary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[hover\=true\]\:text-secondary-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: rgb(135 150 169 / var(--tw-text-opacity));
}

.data-\[hover\=true\]\:text-secondary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:data-\[editable\=true\]\:text-danger[data-editable="true"][data-invalid="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:text-danger-300[data-invalid="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-300) / var(--nextui-danger-300-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-background[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-danger-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-primary-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-secondary-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-success-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-warning-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:data-\[outside-month\=true\]\:text-default-300[data-outside-month="true"][data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-danger-500[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-foreground[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-primary[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-secondary[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-success-600[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-warning-500[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-500) / var(--nextui-warning-500-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-background[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-danger-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-primary-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-secondary-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-warning-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-background[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-danger-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-primary-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-secondary-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-warning-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-background[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-default-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-primary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[selected\=true\]\:text-primary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-secondary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[selected\=true\]\:text-secondary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[unavailable\=true\]\:text-default-300[data-unavailable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[unavailable\=true\]\:line-through[data-unavailable="true"] {
  text-decoration-line: line-through;
}

.data-\[disabled\=true\]\:data-\[outside-month\=true\]\:opacity-0[data-outside-month="true"][data-disabled="true"] {
  opacity: 0;
}

.data-\[disabled\=true\]\:opacity-30[data-disabled="true"] {
  opacity: .3;
}

.data-\[hover-unselected\=true\]\:opacity-disabled[data-hover-unselected="true"] {
  opacity: var(--nextui-disabled-opacity);
}

.data-\[hover\=true\]\:opacity-hover[data-hover="true"] {
  opacity: var(--nextui-hover-opacity);
}

.data-\[in-range\=true\]\:opacity-100[data-in-range="true"] {
  opacity: 1;
}

.data-\[invisible\=true\]\:opacity-0[data-invisible="true"] {
  opacity: 0;
}

.data-\[loaded\=true\]\:opacity-100[data-loaded="true"], .data-\[moving\]\:opacity-100[data-moving] {
  opacity: 1;
}

.data-\[pressed\=true\]\:opacity-50[data-pressed="true"] {
  opacity: .5;
}

.data-\[pressed\=true\]\:opacity-70[data-pressed="true"] {
  opacity: .7;
}

.data-\[visible\=true\]\:opacity-100[data-visible="true"] {
  opacity: 1;
}

.data-\[active\=true\]\:shadow-md[data-active="true"] {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[hover\=true\]\:shadow-lg[data-hover="true"] {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:shadow-md[data-selection-end="true"][data-selected="true"], .data-\[selected\=true\]\:data-\[selection-start\=true\]\:shadow-md[data-selection-start="true"][data-selected="true"], .data-\[selected\=true\]\:shadow-md[data-selected="true"] {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[selected\=true\]\:shadow-none[data-selected="true"] {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[active\=true\]\:shadow-danger\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-default\/50[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-primary\/40[data-active="true"] {
  --tw-shadow-color: #00badb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-secondary\/40[data-active="true"] {
  --tw-shadow-color: #444f5f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-success\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-warning\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-danger\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-default\/50[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-primary\/30[data-hover="true"] {
  --tw-shadow-color: #00badb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-secondary\/30[data-hover="true"] {
  --tw-shadow-color: #444f5f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-success\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-warning\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-danger\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-foreground\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--nextui-foreground) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-primary\/40[data-selected="true"] {
  --tw-shadow-color: #00badb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-secondary\/40[data-selected="true"] {
  --tw-shadow-color: #444f5f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-success\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-warning\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[focus-visible\=true\]\:outline-2[data-focus-visible="true"] {
  outline-width: 2px;
}

.data-\[focus-visible\=true\]\:outline-offset-2[data-focus-visible="true"] {
  outline-offset: 2px;
}

.data-\[focus-visible\=true\]\:outline-focus[data-focus-visible="true"] {
  outline-color: #00badb;
}

.data-\[focus-visible\]\:outline-danger-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-default-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-primary-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-secondary-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-success-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-warning-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, 1));
}

.data-\[menu-open\=true\]\:backdrop-blur-xl[data-menu-open="true"] {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.data-\[disabled\=true\]\:transition-none[data-disabled="true"] {
  transition-property: none;
}

.data-\[hover\=true\]\:transition-colors[data-hover="true"] {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.data-\[moving\=true\]\:transition-transform[data-moving="true"] {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.data-\[hide-scroll\=true\]\:scrollbar-hide[data-hide-scroll="true"] {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.data-\[hide-scroll\=true\]\:scrollbar-hide[data-hide-scroll="true"]::-webkit-scrollbar {
  display: none;
}

.data-\[top-bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[top-scroll\=true\]\:\[mask-image\:linear-gradient\(0deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-scroll="true"] {
  -webkit-mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(180deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-scroll\=true\]\:\[mask-image\:linear-gradient\(270deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-scroll="true"] {
  -webkit-mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[right-scroll\=true\]\:\[mask-image\:linear-gradient\(90deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-right-scroll\=true\]\:\[mask-image\:linear-gradient\(to_right\,\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[placement\=bottom-end\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom-end"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom-end\]\:before\:right-3[data-placement="bottom-end"]:before {
  content: var(--tw-content);
  right: .75rem;
}

.data-\[placement\=bottom-start\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom-start"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom-start\]\:before\:left-3[data-placement="bottom-start"]:before {
  content: var(--tw-content);
  left: .75rem;
}

.data-\[placement\=bottom\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom\]\:before\:left-1\/2[data-placement="bottom"]:before {
  content: var(--tw-content);
  left: 50%;
}

.data-\[placement\=left-end\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="left-end"]:before {
  content: var(--tw-content);
  right: calc(3px - .3125rem);
}

.data-\[placement\=left-end\]\:before\:bottom-1\/4[data-placement="left-end"]:before {
  content: var(--tw-content);
  bottom: 25%;
}

.data-\[placement\=left-start\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="left-start"]:before {
  content: var(--tw-content);
  right: calc(3px - .3125rem);
}

.data-\[placement\=left-start\]\:before\:top-1\/4[data-placement="left-start"]:before {
  content: var(--tw-content);
  top: 25%;
}

.data-\[placement\=left\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_2px\)\][data-placement="left"]:before {
  content: var(--tw-content);
  right: calc(2px - .3125rem);
}

.data-\[placement\=left\]\:before\:top-1\/2[data-placement="left"]:before {
  content: var(--tw-content);
  top: 50%;
}

.data-\[placement\=right-end\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="right-end"]:before {
  content: var(--tw-content);
  left: calc(3px - .3125rem);
}

.data-\[placement\=right-end\]\:before\:bottom-1\/4[data-placement="right-end"]:before {
  content: var(--tw-content);
  bottom: 25%;
}

.data-\[placement\=right-start\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="right-start"]:before {
  content: var(--tw-content);
  left: calc(3px - .3125rem);
}

.data-\[placement\=right-start\]\:before\:top-1\/4[data-placement="right-start"]:before {
  content: var(--tw-content);
  top: 25%;
}

.data-\[placement\=right\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_2px\)\][data-placement="right"]:before {
  content: var(--tw-content);
  left: calc(2px - .3125rem);
}

.data-\[placement\=right\]\:before\:top-1\/2[data-placement="right"]:before {
  content: var(--tw-content);
  top: 50%;
}

.data-\[placement\=top-end\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top-end"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top-end\]\:before\:right-3[data-placement="top-end"]:before {
  content: var(--tw-content);
  right: .75rem;
}

.data-\[placement\=top-start\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top-start"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top-start\]\:before\:left-3[data-placement="top-start"]:before {
  content: var(--tw-content);
  left: .75rem;
}

.data-\[placement\=top\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top\]\:before\:left-1\/2[data-placement="top"]:before {
  content: var(--tw-content);
  left: 50%;
}

.data-\[loaded\=true\]\:before\:-z-10[data-loaded="true"]:before {
  content: var(--tw-content);
  z-index: -10;
}

.data-\[arrow\=true\]\:before\:block[data-arrow="true"]:before {
  content: var(--tw-content);
  display: block;
}

.data-\[outside-month\=true\]\:before\:hidden[data-outside-month="true"]:before {
  content: var(--tw-content);
  display: none;
}

.data-\[placement\=bottom\]\:before\:-translate-x-1\/2[data-placement="bottom"]:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=left\]\:before\:-translate-y-1\/2[data-placement="left"]:before, .data-\[placement\=right\]\:before\:-translate-y-1\/2[data-placement="right"]:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=top\]\:before\:-translate-x-1\/2[data-placement="top"]:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[loaded\=true\]\:before\:animate-none[data-loaded="true"]:before {
  content: var(--tw-content);
  animation: none;
}

.data-\[range-end\=true\]\:before\:rounded-r-full[data-range-end="true"]:before {
  content: var(--tw-content);
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[range-start\=true\]\:before\:rounded-l-full[data-range-start="true"]:before {
  content: var(--tw-content);
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[selection-end\=true\]\:before\:rounded-r-full[data-selection-end="true"]:before {
  content: var(--tw-content);
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[selection-start\=true\]\:before\:rounded-l-full[data-selection-start="true"]:before {
  content: var(--tw-content);
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-danger-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-foreground\/10[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-foreground) / .1);
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-primary-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-secondary-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-success-100[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-warning-100[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:before\:opacity-0[data-loaded="true"]:before {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:before\:opacity-100[data-selected="true"]:before {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[active\=true\]\:after\:w-full[data-active="true"]:after, .data-\[focus\=true\]\:after\:w-full[data-focus="true"]:after, .data-\[open\=true\]\:after\:w-full[data-open="true"]:after {
  content: var(--tw-content);
  width: 100%;
}

.data-\[dragging\=true\]\:after\:scale-100[data-dragging="true"]:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[dragging\=true\]\:after\:scale-80[data-dragging="true"]:after {
  content: var(--tw-content);
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:after\:bg-danger-400[data-active="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-400) / var(--nextui-danger-400-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-danger[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-foreground[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-primary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:after\:bg-secondary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selected\=true\]\:after\:bg-success[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-warning[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:after\:opacity-0[data-loaded="true"]:after {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:after\:opacity-100[data-selected="true"]:after {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[selectable\=true\]\:focus\:border-danger:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-default:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-primary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.data-\[selectable\=true\]\:focus\:border-secondary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.data-\[selectable\=true\]\:focus\:border-success:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-warning:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[invalid\=true\]\:focus\:bg-danger-400\/50:focus[data-invalid="true"] {
  background-color: hsl(var(--nextui-danger-400) / .5);
}

.data-\[selectable\=true\]\:focus\:bg-danger:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-danger\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-danger) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-default:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default-100:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default\/40:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-default) / .4);
}

.data-\[selectable\=true\]\:focus\:bg-primary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.data-\[selectable\=true\]\:focus\:bg-primary\/20:focus[data-selectable="true"] {
  background-color: #00badb33;
}

.data-\[selectable\=true\]\:focus\:bg-secondary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.data-\[selectable\=true\]\:focus\:bg-secondary\/20:focus[data-selectable="true"] {
  background-color: #444f5f33;
}

.data-\[selectable\=true\]\:focus\:bg-success:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-success\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-success) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-warning:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-warning\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-warning) / .2);
}

.data-\[editable\=true\]\:focus\:text-danger:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-default-foreground:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-primary:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:focus\:text-secondary:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[editable\=true\]\:focus\:text-success:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-success-600:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-warning:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-warning-600:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:data-\[editable\=true\]\:focus\:text-danger:focus[data-editable="true"][data-invalid="true"], .data-\[selectable\=true\]\:focus\:text-danger:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-danger-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-500:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-primary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.data-\[selectable\=true\]\:focus\:text-primary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-secondary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.data-\[selectable\=true\]\:focus\:text-secondary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:shadow-danger\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-default\/50:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-primary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: #00badb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-secondary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: #444f5f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-success\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-warning\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:pointer-events-auto, .group[data-has-value="true"] .group-data-\[has-value\=true\]\:pointer-events-auto {
  pointer-events: auto;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:start-0, .group[data-filled="true"] .group-data-\[filled\=true\]\:start-0 {
  inset-inline-start: 0;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:z-10 {
  z-index: 10;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-4 {
  margin-inline-start: 1rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-5 {
  margin-inline-start: 1.25rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-6 {
  margin-inline-start: 1.5rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-3 {
  margin-left: .75rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-4 {
  margin-left: 1rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-5 {
  margin-left: 1.25rem;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:block, .group[data-has-value="true"] .group-data-\[has-value\=true\]\:block {
  display: block;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:flex {
  display: flex;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:hidden {
  display: none;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-5 {
  width: 1.25rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-6 {
  width: 1.5rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-7 {
  width: 1.75rem;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_3\.5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 3.5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 6px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 6px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 8px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 8px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_3\.5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 3.5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 6px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 6px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 8px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 8px - var(--nextui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:scale-85, .group[data-filled="true"] .group-data-\[filled\=true\]\:scale-85 {
  --tw-scale-x: .85;
  --tw-scale-y: .85;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/tr[data-disabled="true"] .group-data-\[disabled\=true\]\/tr\:cursor-not-allowed {
  cursor: not-allowed;
}

.group[data-has-multiple-months="true"] .group-data-\[has-multiple-months\=true\]\:flex-row {
  flex-direction: row;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:items-start {
  align-items: flex-start;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:items-end {
  align-items: flex-end;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:\!border-danger {
  --tw-border-opacity: 1 !important;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity))) !important;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-default-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:border-danger, .group[data-selected="true"] .group-data-\[selected\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-default-500 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 186 219 / var(--tw-border-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(68 79 95 / var(--tw-border-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:\!bg-danger-50 {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity))) !important;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-50) / var(--nextui-default-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.group[data-hover-unselected="true"] .group-data-\[hover-unselected\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 219 / var(--tw-bg-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(68 79 95 / var(--tw-bg-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-2 {
  padding-top: .5rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-3 {
  padding-top: .75rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-4, .group[data-has-label="true"] .group-data-\[has-label\=true\]\:pt-4 {
  padding-top: 1rem;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:pt-5 {
  padding-top: 1.25rem;
}

.group\/tr[data-disabled="true"] .group-data-\[disabled\=true\]\/tr\:text-foreground-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-300) / var(--nextui-foreground-300-opacity, var(--tw-text-opacity)));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-600) / var(--nextui-default-600-opacity, var(--tw-text-opacity)));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-600) / var(--nextui-default-600-opacity, var(--tw-text-opacity)));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:text-danger, .group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 186 219 / var(--tw-text-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary {
  --tw-text-opacity: 1;
  color: rgb(68 79 95 / var(--tw-text-opacity));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:opacity-70 {
  opacity: .7;
}

.group\/th[data-hover="true"] .group-data-\[hover\=true\]\/th\:opacity-100, .group[data-loaded="true"] .group-data-\[loaded\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:opacity-70 {
  opacity: .7;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-60 {
  opacity: .6;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 186 219 / var(--tw-ring-opacity));
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-background {
  --tw-ring-offset-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, 1));
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:-z-10:before {
  content: var(--tw-content);
  z-index: -10;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:translate-y-px:before {
  content: var(--tw-content);
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/tr[data-middle="true"] .group-data-\[middle\=true\]\/tr\:before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.group[data-hover="true"] .group-data-\[hover\=true\]\:before\:bg-default-100:before, .group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:ml-0\.5:after {
  content: var(--tw-content);
  margin-left: .125rem;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:translate-y-0:after {
  content: var(--tw-content);
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:-rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:scale-100:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:after\:bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:text-danger:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:hover\:border-danger:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:hover\:bg-danger-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:focus-within\:hover\:border-danger:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:focus-within\:hover\:bg-danger-50:hover:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group\/tr[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\/tr\:group-data-\[hover\=true\]\/tr\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group\/tr[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\/tr\:group-data-\[hover\=true\]\/tr\:before\:opacity-70:before {
  content: var(--tw-content);
  opacity: .7;
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-danger\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-danger) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-default\/60[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-default) / .6);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-primary\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: #00badb33;
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-secondary\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: #444f5f33;
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-success\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-success) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-warning\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-warning) / .2);
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:pointer-events-auto {
  pointer-events: auto;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:block {
  display: block;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:opacity-70 {
  opacity: .7;
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .motion-reduce\:transition-none {
    transition-property: none;
  }

  .motion-reduce\:after\:transition-none:after {
    content: var(--tw-content);
    transition-property: none;
  }
}

.dark\:border-danger-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-border-opacity)));
}

.dark\:border-default-200:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-border-opacity)));
}

.dark\:border-primary-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(202 253 245 / var(--tw-border-opacity));
}

.dark\:border-success-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-border-opacity)));
}

.dark\:border-warning-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-border-opacity)));
}

.dark\:bg-background:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-background\/20:is(.dark *) {
  background-color: hsl(var(--nextui-background) / .2);
}

.dark\:bg-content2:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-50\/50:is(.dark *) {
  background-color: hsl(var(--nextui-danger-50) / .5);
}

.dark\:bg-default:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-default-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-default-50\/50:is(.dark *) {
  background-color: hsl(var(--nextui-default-50) / .5);
}

.dark\:bg-primary-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(202 253 245 / var(--tw-bg-opacity));
}

.dark\:bg-primary-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(235 254 255 / var(--tw-bg-opacity));
}

.dark\:bg-primary-50\/50:is(.dark *) {
  background-color: #ebfeff80;
}

.dark\:bg-secondary-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 242 / var(--tw-bg-opacity));
}

.dark\:bg-secondary-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}

.dark\:bg-secondary-50\/50:is(.dark *) {
  background-color: #f6f7f980;
}

.dark\:bg-success-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-success-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-success-50\/50:is(.dark *) {
  background-color: hsl(var(--nextui-success-50) / .5);
}

.dark\:bg-transparent:is(.dark *) {
  background-color: #0000;
}

.dark\:bg-warning-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-warning-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-warning-50\/50:is(.dark *) {
  background-color: hsl(var(--nextui-warning-50) / .5);
}

.dark\:text-danger-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:text-success:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.dark\:text-warning:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-danger-500:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-success:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-warning:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:before\:via-default-700\/10:is(.dark *):before {
  content: var(--tw-content);
  --tw-gradient-to: hsl(var(--nextui-default-700) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--nextui-default-700) / .1) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.dark\:after\:bg-content2:is(.dark *):after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:focus\:bg-danger-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--nextui-danger-400) / .2);
}

.dark\:focus\:bg-success-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--nextui-success-400) / .2);
}

.dark\:focus\:bg-warning-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--nextui-warning-400) / .2);
}

.dark\:data-\[hover\=true\]\:bg-content2[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-danger-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-success-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-warning-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-danger[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-success[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-warning[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:text-danger-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[hover\=true\]\:text-success-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-500) / var(--nextui-success-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[hover\=true\]\:text-warning-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-500) / var(--nextui-warning-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-danger-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-success-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-warning-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-success-500[data-range-selection="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-500) / var(--nextui-success-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"]:is(.dark *), .dark\:data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-danger-500[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-success[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-warning[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-success-50[data-range-selection="true"][data-selected="true"]:is(.dark *):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-warning-50[data-range-selection="true"][data-selected="true"]:is(.dark *):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[invalid\=true\]\:focus\:bg-danger-400\/20:focus[data-invalid="true"]:is(.dark *) {
  background-color: hsl(var(--nextui-danger-400) / .2);
}

@media (width >= 640px) {
  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:\[--scale-enter\:100\%\] {
    --scale-enter: 100%;
  }

  .sm\:\[--scale-exit\:103\%\] {
    --scale-exit: 103%;
  }

  .sm\:\[--slide-enter\:0px\] {
    --slide-enter: 0px;
  }

  .sm\:\[--slide-exit\:0px\] {
    --slide-exit: 0px;
  }

  .sm\:data-\[visible\=true\]\:pointer-events-none[data-visible="true"] {
    pointer-events: none;
  }

  .sm\:data-\[visible\=true\]\:opacity-0[data-visible="true"] {
    opacity: 0;
  }

  .group[data-hover="true"] .sm\:group-data-\[hover\=true\]\:data-\[visible\=true\]\:pointer-events-auto[data-visible="true"] {
    pointer-events: auto;
  }

  .group[data-hover="true"] .sm\:group-data-\[hover\=true\]\:data-\[visible\=true\]\:opacity-100[data-visible="true"] {
    opacity: 1;
  }
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-3\/4 {
    width: 75%;
  }
}

@media (width >= 1024px) {
  .lg\:h-1\/3 {
    height: 33.3333%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.rtl\:left-2:where([dir="rtl"], [dir="rtl"] *) {
  left: .5rem;
}

.rtl\:right-auto:where([dir="rtl"], [dir="rtl"] *) {
  right: auto;
}

.rtl\:origin-top-right:where([dir="rtl"], [dir="rtl"] *) {
  transform-origin: 100% 0;
}

.rtl\:-rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:flex-row-reverse:where([dir="rtl"], [dir="rtl"] *) {
  flex-direction: row-reverse;
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.rtl\:data-\[focus-visible\=true\]\:translate-x-3[data-focus-visible="true"]:where([dir="rtl"], [dir="rtl"] *), .rtl\:data-\[hover\=true\]\:translate-x-3[data-hover="true"]:where([dir="rtl"], [dir="rtl"] *) {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:data-\[open\=true\]\:-rotate-90[data-open="true"]:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\+\.border-medium\.border-danger\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-danger, .\[\&\+\.border-medium\.border-default\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-default, .\[\&\+\.border-medium\.border-primary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-primary, .\[\&\+\.border-medium\.border-secondary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-secondary, .\[\&\+\.border-medium\.border-success\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-success, .\[\&\+\.border-medium\.border-warning\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-warning {
  margin-inline-start: calc(var(--nextui-border-width-medium) * -1);
}

.\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:rounded-none:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.\[\&\:not\(\:first-child\)\]\:-ml-1:not(:first-child) {
  margin-left: -.25rem;
}

.\[\&\:not\(\:first-of-type\)\:not\(\:last-of-type\)\]\:rounded-none:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.\[\&\:not\(\:first-of-type\)\]\:ms-\[calc\(theme\(borderWidth\.2\)\*-1\)\]:not(:first-of-type) {
  margin-inline-start: -2px;
}

.\[\&\>\*\]\:relative > * {
  position: relative;
}

.\[\&\>svg\]\:max-w-\[theme\(spacing\.8\)\] > svg {
  max-width: 2rem;
}

.\[\&\>tr\]\:first\:rounded-lg:first-child > tr {
  border-radius: .5rem;
}

.\[\&\>tr\]\:first\:shadow-small:first-child > tr {
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\[data-hover\=true\]\:not\(\[data-active\=true\]\)\]\:bg-default-100[data-hover="true"]:not([data-active="true"]) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.\[\&\[data-hover\=true\]\:not\(\[data-active\=true\]\)\]\:bg-default-200[data-hover="true"]:not([data-active="true"]) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.\[\&_\.chevron-icon\]\:flex-none .chevron-icon {
  flex: none;
}

.\[\&_\.chevron-icon\]\:rotate-180 .chevron-icon {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&_\.chevron-icon\]\:transition-transform .chevron-icon {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}
/*# sourceMappingURL=index.b52e74a7.css.map */
